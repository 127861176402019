@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

@layer components {
    .section-header {
        --tw-border-opacity: 0.4;
        padding-bottom: 0.75rem /* pb-3 */;
        margin-bottom: 0.75rem /* mb-3 */;
        color: rgba(255, 255, 255, 1); /* text-white */
        border-bottom-width: 1px; /* border-b */
        font-size: 1.5rem; /* text-2xl */
        line-height: 2rem; /* text-2xl */
        @apply border-kaz-blue;
    }

    .role-list {
        list-style-type: disc;
        list-style-position: inside;
        margin-bottom: 1rem;
    }
    .role-list > li::before {
        content: "•";
        margin-right: 0.5rem;
        font-weight: bold;
        margin-top: auto;
        margin-bottom: auto;
    }
}

body {
    background: #071013;
    font-family: "Roboto", sans-serif;
    margin: 0;
    /* setting margin/padding to zero makes page touch all borders */
    padding: 0;
    font-size: 1vw;
    line-height: normal;
    color: white;
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    color: white;
}

a {
    color: white;
}

footer {
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
    padding-top: 50px;
    padding-bottom: 20px;
}

p {
    color: white;
}

/* Scrollbar */
body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: linear-gradient(#44ffd1 25%, #5bc3eb 75%);
    border-radius: 10px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
}

/* Scrollbar for textarea */
textarea::-webkit-scrollbar {
    width: 10px;
}

/* Track */
textarea::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 30px;
    margin-bottom: 30px;
}

/* Handle */
textarea::-webkit-scrollbar-thumb {
    background: linear-gradient(#44ffd1 25%, #5bc3eb 75%);
    border-radius: 10px;
}

/* Handle on hover */
textarea::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
}

textarea::-webkit-scrollbar-corner {
    background-color: #5bc3eb;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    background: linear-gradient(#39d4ad 25%, #4ca3c5 75%);
    animation: spin 1s linear infinite;
}
  
.spinner::before {
    content: '';
    position: absolute;
    inset: 4px;
    border-radius: 50%;
    background: #071013;
    z-index: 1;
}

/* Adjust the flex basis and margins for smaller screens */
@media (max-width: 768px) {
    .affiliates-list .column {
        flex-basis: calc(50% - 20px);
        /* Two logos per row */
        margin: 10px 5px;
        max-width: 50%;
    }
}

/* makes the services columns stack vertically once they can't fit side by side */
@media (max-width: 1200px) {
    .columns {
        flex-direction: column;
    }

    .column {
        flex: 0 1 100%;
        max-width: 300px;
        margin-bottom: 100px;
        /* Add some space below each column */
    }

    body {
        font-size: 18px;
    }

    .circle-image {
        min-width: 250px;
        min-height: 250px;
    }

    .about-text {
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%;
    }
}

@keyframes fade-in-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}
  
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  